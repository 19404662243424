// Stylesheet import
import "./src/css/styles.scss"
import React from "react"
import { ContextWrapper } from "./src/store/AppContext"

export const wrapRootElement = ({ element }) => (
  <ContextWrapper>{element}</ContextWrapper>
)

// trigger an immediate page refresh when an update is found
export const onServiceWorkerUpdateReady = () => window.location.reload()
