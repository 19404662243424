import React, { useState } from "react"
const defaultState = {
  display: false,
  customNav: true, // Toggle for custom nav items
  topBanner: false, // Toggle for top text banner
  banner: false, // Toggle for banner image
  toggle: () => {},
}

export const AppContext = React.createContext(defaultState)

export const ContextWrapper = props => {
  const [state, setState] = useState({
    display: false,
    customNav: false,
    topBanner: false,
    banner: false,
  })
  const toggle = status => {
    setState({
      display: status,
    })
  }

  return (
    <AppContext.Provider value={{ state, toggle }}>
      {props.children}
    </AppContext.Provider>
  )
}
